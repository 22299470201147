import React from "react"
import { css } from "@emotion/core"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography.js"

export default function BlogPage({ data }) {
  return (
    <div
      css={css`
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
      `}
    >
      <title> Blogs - Josh Burns - SDET</title>

      <Layout>
        <br />
        <br />
        <h1> Blogs </h1>
        <span>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <div>
              <Link to={node.fields.slug}>
                <span
                  css={css`
                    margin: ${rhythm(0.2)};
                  `}
                >
                  <h2>{node.frontmatter.title} </h2>
                </span>
              </Link>
              <div>
                <i> ({node.frontmatter.date} )</i>
              </div>
              <span>
                <i>{node.excerpt}</i>
              </span>
            </div>
          ))}
        </span>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { eq: "blog" } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            permalink
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
